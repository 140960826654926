import * as React from "react"
import { Button, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "../teacher.module.scss"
import * as teacher from "../../../../shared/routes/teacher"
import { AssignmentResponse, ClassResponse } from "../../../../shared/types/teacherTypes"
import DeleteAssignment from "../Forms/DeleteAssignment"
import ToolTip from "../../../General/ToolTip"
import { useModel } from '@stem-sims/nexus'
import lessons from "shared/lessons"
import { TeacherGradesState } from "components/Lessons/Grading/GradeList/TeacherGrades"
import Assignments from "components/Dashboards/General/Assignments"
import EmptyTablePreview from "../../General/EmptyTablePreview"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpLinkVideos, helpSteps } from "./assignmentsHelpData"

import BulkAssignModal from "../Forms/AssignmentForm/BulkComponents/BulkAssignModal"
import BulkCopyModal from "../Forms/AssignmentForm/BulkComponents/BulkCopyModal"
import { AuthContext } from "AuthContext"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import books from "shared/routes/curriculum/books"
import { AssignmentPreviewStateProps } from "components/Lessons/Grading/AssignmentPreview/Preview"
interface Props {
    activeClass: ClassResponse
    refreshClasses: () => void
}

function NoAssignmentsView({onClickAdd, onClickAddTopic}: {onClickAdd: () => void, onClickAddTopic: () => void}) {
    const { hasCurriculumAccess } = React.useContext(AuthContext)
    return (
        <>
            <EmptyTablePreview
                title="Create Assignments"
                description="Once you add an assignment the entire class will be able to see and submit it."
            >
                <Button 
                    variant="theme"
                    onClick={onClickAdd}
                    data-testid="empty-add-assignment"
                ><i className="fas fa-plus pe-2" />
                <span className="d-none d-md-inline">Assignment</span>
                </Button>
                {hasCurriculumAccess && 
                    <Button 
                        className="ms-2"
                        onClick={onClickAddTopic}
                        variant="theme">
                        <i className="fas fa-plus pe-2" />
                        <span className="d-none d-md-inline">Textbook Topic</span>
                    </Button>}
            </EmptyTablePreview>
        </>
    )
}

export default function TeacherAssignments({ activeClass, refreshClasses }: Props) {
    const [deleteAssignmentShown, setDeleteAssignmentShown] = React.useState(false)
    const [assignmentToBeDeleted, setAssignmentToBeDeleted] = React.useState<AssignmentResponse | null>(null)

    /* states for bulk operations */
    const [bulkDelete, setBulkDelete] = React.useState<boolean>(false)
    const [selectedAssignments, setSelectedAssignments] = React.useState<string[]>([])
    const [showBulkAsignModal, setShowBulkAssignModal] = React.useState<boolean>(false)
    const [showBulkCopyModal, setShowBulkCopyModal] = React.useState<boolean>(false)

    const navigate = useNavigate()

    function assignmentError(err) {
        toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")
        refreshClasses()
    }

    const { response: assignments, loading, refreshModel: reloadAssignments} = useModel({
        model: teacher.getAssignments,
        onError: assignmentError,
        props: { classID: activeClass.id }
    })

    React.useEffect(() => {
        reloadAssignments()
        setSelectedAssignments([])
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [activeClass])

    const extraCols = [
        {
            id: 'Actions',
            disableSortBy: true,
            Cell: ({ row }) => (
                <div className="d-flex justify-content-center">
                    <ToolTip title="View Assignment">
                        <Button
                            className="me-2"
                            variant="outline-primary"
                            aria-label="View Assignment"
                            disabled={!row.original.userHasAccess}
                            onClick={(e) => {
                                e.stopPropagation()
                                navigate("/dashboard/teacher/assignments/grading/preview", {
                                    state: {
                                        assignment: row.original,
                                        lesson: row.original.lesson,
                                        origin: "assignments",
                                    } as AssignmentPreviewStateProps
                                })
                            }}
                        >
                            <i className="fas fa-glasses" />
                        </Button>
                    </ToolTip>
                    <ToolTip title="Edit Assignment">
                        <Button
                            className="me-2 class-assignment-edit"
                            variant="outline-primary"
                            aria-label="Edit Assignment"
                            disabled={!row.original.userHasAccess}
                            onClick={(e) => {
                                e.stopPropagation()
                                navigate("/dashboard/teacher/assignments/edit", {
                                    state: {
                                        assignmentId: row.original.id,
                                        lessonId: row.original.lessonId,
                                    }
                                })
                            }}
                        >
                            <i className="fas fa-edit" />
                        </Button>
                    </ToolTip>
                    <ToolTip title="Delete Assignment">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setAssignmentToBeDeleted(row.original)
                                setBulkDelete(false)
                                setDeleteAssignmentShown(true)
                            }}
                            variant="outline-danger"
                            aria-label="Delete Assignment"
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    </ToolTip>
                </div>
            ),
        }
    ]

    const SelectAllCheckbox = (table: { page: Array<{ id: string }> }) => {
        return <Form.Check
            id="select-all-checkbox"
            type="checkbox"
            className={`${styles.checkAll}`}
            aria-label="select-all-checkbox"
            onClick={(e) => e.stopPropagation()}
            checked={table.page.length > 0 && table.page.length === selectedAssignments.length}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    setSelectedAssignments(table.page.map(row => row.id))
                } else {
                    setSelectedAssignments([])
                }
            }}
        />
    }

    const checkboxCol = {
        Header: SelectAllCheckbox,
        disableSortBy: true,
        maxWidth: 15,
        accessor: 'select',
        Cell: ({ row }) => {
            return (
                <Form.Check
                    key={`${row.original.id}`}
                    id={`${row.original.id}`}
                    type="checkbox"
                    className={styles.assignmentCheckbox}
                    aria-label={`${row.original.title}`}
                    onClick={(e) => e.stopPropagation()}
                    checked={selectedAssignments.includes(row.original.id)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setSelectedAssignments(prev => [...prev, row.original.id])
                        } else {
                            setSelectedAssignments(prev => [...prev.filter(id => id !== row.original.id)])
                        }
                    }}
                />
            )
        }
    }

    const onRowClick = async (row) => {
        const assignment: AssignmentResponse = row.original
        if (assignment.lessonVariety === "Guided" || assignment.lessonVariety === "Topic") {
            let topic: CurriculumTopic
            if(assignment.lessonId) {
                var lesson = await lessons.findById(assignment.lessonId)
            }

            if (assignment.topicId) {
                topic = await books.getTopic({ topicId: assignment.topicId })
            }


            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment,
                lesson: lesson,
                topic: topic,
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment  
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        }
    }

    const changeBackgroundColor = (row) => {
        const assignment: AssignmentResponse = row.original
        return assignment.userHasAccess ? "" : `${styles.unavailableAssignment}`
    }

    const onClickAddAssignments = () => {
        // reset selectedGradeInit and initStandardId filters so that grade level and standard id from current class would be pre-selected in lesson search page
        localStorage.removeItem("selectedGrades") 
        localStorage.removeItem("selectedStandardId")
        navigate("/dashboard/teacher/assignments/search")
    }

    const onClickAddTopicAssignments = () => {
        navigate("/books")
    }

    const AssignmentsComponent = <Assignments assignments={assignments} onAddAssignment={onClickAddAssignments} onAddTopicAssignment={onClickAddTopicAssignments} onRowClick={onRowClick} changeBackgroundColor={changeBackgroundColor} extraCols={extraCols} checkboxCol={checkboxCol} loading={loading} rowTooltip="Grade Assignment" />
    const EmptyTableComponent = <NoAssignmentsView onClickAdd={onClickAddAssignments} onClickAddTopic={onClickAddTopicAssignments} />
    return (
        <>
            <RelevantHelpModal videos={helpLinkVideos} helpSteps={assignments?.length > 0 && helpSteps}/>
            <h1 className="h2 text-start mt-3">Class Assignments</h1>
            <p className={`text-start ${styles.greyText}`}>
                Click row below to grade an assignment or use corresponding buttons to edit or delete an assignment.
            </p>
            <div className={`d-flex align-items-center position-relative`}>
                <div className={`p-0 ${styles.bulkItemsContainer} ${selectedAssignments.length > 0 ? styles.displayActions : styles.hiddenActions}`}>
                    <div className={`d-flex align-items-center p-0 mb-0 position-relative`}>
                        <ToolTip title="Delete Selected Assignments" classNames={selectedAssignments.length > 0 ? "d-block" : "d-none"}>
                            <Button
                                variant=""
                                className="border-end rounded-0"
                                disabled={selectedAssignments.length === 0}
                                onClick={() => {
                                    setBulkDelete(true)
                                    setDeleteAssignmentShown(true)
                                }} >
                                <i className="fas fa-trash-alt pe-2 text-red" />
                                <span className="d-none d-md-inline">Delete</span>
                            </Button>
                        </ToolTip>
                        <ToolTip title="Assign To Class or Students" classNames={selectedAssignments.length > 0 ? "d-block" : "d-none"}>
                            <Button
                                variant=""
                                className="border-end rounded-0"
                                disabled={selectedAssignments.length === 0}
                                onClick={() => {
                                    setShowBulkAssignModal(true)
                                }} >
                                <i className="fas fa-plus pe-2" />
                                <span className="d-none d-md-inline">Assign</span>
                            </Button>
                        </ToolTip>
                        <ToolTip title="Copy To Another Class" classNames={selectedAssignments.length > 0 ? "d-block" : "d-none"}>
                            <Button
                                variant=""
                                disabled={selectedAssignments.length === 0}
                                onClick={() => {
                                    setShowBulkCopyModal(true)
                                }} >
                                <i className="fas fa-copy pe-2" />
                                <span className="d-none d-md-inline">Copy</span>
                            </Button>
                        </ToolTip>
                        <span className={`bg-primary text-white rounded-pill ${styles.selectedCount}`}>{selectedAssignments.length}</span>
                    </div>
                </div>
            </div>

            {(loading || assignments?.length > 0) ? AssignmentsComponent : EmptyTableComponent}
            <DeleteAssignment
                bulkDelete={bulkDelete}
                selectedAssignmentIds={selectedAssignments}
                assignment={assignmentToBeDeleted}
                show={deleteAssignmentShown}
                onHide={() => setDeleteAssignmentShown(false)}
                onSuccess={() => {
                    reloadAssignments()
                    setSelectedAssignments([])
                }}
            />
            <BulkAssignModal
                activeClass={activeClass}
                show={showBulkAsignModal}
                onHide={() => setShowBulkAssignModal(false)}
                onSuccess={() => {
                    setShowBulkAssignModal(false)
                    setSelectedAssignments([])
                }}
                selectedAssignmentIds={selectedAssignments}
            />
            <BulkCopyModal
                activeClass={activeClass}
                show={showBulkCopyModal}
                onHide={() => setShowBulkCopyModal(false)}
                onSuccess={() => {
                    setShowBulkCopyModal(false)
                    setSelectedAssignments([])
                }}
                selectedAssignmentIds={selectedAssignments}
            />
        </>
    )
}

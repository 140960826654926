import * as React from "react"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import SidebarContent from "../../Sidebar/Content"
import SidebarContainer from "../../Sidebar/SidebarContainer"
import ClassSidebar from "../General/ClassSidebar"
import TeacherAddClass from "./Forms/AddClass"
import * as teacher from "../../../shared/routes/teacher"
import { ClassResponse } from "../../../shared/types/teacherTypes"
import findById from "../../../helpers/findById"
import refreshClassesFactory from "../General/Helpers/refreshClassesFactory"
import DashboardAuth from "../General/Auth/DashboardAuth"

/*
* Page Components
*/
import TeacherOverview from "./Overview"
import TeacherAssignments from "./Assignments/TeacherAssignments"
import TeacherAssignmentSearch from "./Assignments/AssignmentSearch"
import TeacherCreateAssignment, { CreateAssignmentProps } from "./Assignments/CreateAssignment"
import TeacherEditAssignment, { EditAssignmentProps } from "./Assignments/EditAssignment"
import TeacherStudents from "./Students"
import TeacherClassSettings from "./Settings"
import TeacherAssignmentGrading from "./Assignments/AssignmentGrading"
import checkRouteState from "helpers/checkRouteState"
import Analytics from "./Analytics"
import GradeBook from "./GradeBook"
import { faHome, faTasks, faUserGraduate, faBook, faChartLine, faCog, faFolder, faMicroscope } from "@fortawesome/free-solid-svg-icons"
import LessonPlans from "./LessonPlans/LessonPlans"
import LessonPlan from "./LessonPlans/LessonPlan"
import PlanAssignmentPreview from "./LessonPlans/PlanAssignmentPreview"
import { SidebarLink } from "components/Sidebar/Links"
import modules from "shared/routes/moduleRoutes"
import SAQLandingPage from "./SAQLandingPage"
import DemoMode from "./DemoMode/DemoMode"

export default function TeacherDashboard() {
    const location = useLocation()
    const { id: createdClassId } = location?.state ? location.state as { id?: string } : { id: undefined } // new class from teacher onboarding quiz
    const [classes, setClasses] = React.useState<ClassResponse[]>([])
    const [createClassShown, setCreateClassShown] = React.useState(false)
    const [activeClass, setActiveClass] = React.useState<ClassResponse>(null)
    const [loading, setLoading] = React.useState(true)
    const refreshClasses = refreshClassesFactory({
        getClasses: teacher.getClasses,
        setLoading,
        setClasses,
        setActiveClass
    })
    const [hasSAQAccess, setHasSAQAccess] = React.useState<boolean>(false)

    const [sidebarContents, setSidebarContents] = React.useState<Array<SidebarLink | "separator">>([
        {
            title: "Overview",
            path: "/dashboard/teacher/overview",
            icon: faHome
        },
        {
            title: "Assignments",
            path: "/dashboard/teacher/assignments",
            icon: faTasks
        },
        {
            title: "Students",
            path: "/dashboard/teacher/students",
            icon: faUserGraduate
        },
        {
            title: "Gradebook",
            path: "/dashboard/teacher/gradebook",
            icon: faBook
        },
        {
            title: "Analytics",
            path: "/dashboard/teacher/analytics",
            icon: faChartLine
        },
        {
            title: "Settings",
            path: "/dashboard/teacher/settings",
            icon: faCog
        },
        "separator",
        {
            title: "Lesson Plans",
            path: "/dashboard/teacher/lesson-plans",
            icon: faFolder,
        },
    ])

    const validateGradingRoute = (routeStateClass, Component, url="/dashboard/teacher/assignments") => {
        const validStateSubmission = checkRouteState(location?.state, routeStateClass)

        if(validStateSubmission) {
            location.state = validStateSubmission
            return Component
        }
        return <Navigate to={url} /> 
    }

    // Component did mounted
    React.useEffect(() => {
        if (createdClassId) {
            void refreshClasses()
            .then((refreshedClasses) => {
                setActiveClass(findById(createdClassId, refreshedClasses))
            })
        } else {
            void refreshClasses()
        }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    // Cache active class
    React.useEffect(() => {
        if (activeClass) {
            localStorage.setItem("activeClass", JSON.stringify(activeClass))
        }
        modules.hasSAQAccess(activeClass?.gradeLevel).then((response) => {
            setHasSAQAccess(response)
        })
    }, [activeClass])

    React.useEffect(() => {
        // Include SAQ Link if the customer has an SAQ Plan and is in 3-5th grade
        if (hasSAQAccess) {
            if (!sidebarContents.find(item => item !== "separator" && item.title === "SAQ")) {
                // Get index of settings link
                const settingsIndex = sidebarContents.findIndex(item => item !== "separator" && item.title === "Settings")
                // Insert SAQ link before settings link
                setSidebarContents([...sidebarContents.slice(0, settingsIndex), {
                    title: "SAQ",
                    path: "/dashboard/teacher/saq",
                        icon: faMicroscope
                    }, ...sidebarContents.slice(settingsIndex)])
            }
        } else {
            const saqIndex = sidebarContents.findIndex(item => item !== "separator" && item.title === "SAQ")
            if (saqIndex !== -1) {
                // Remove SAQ link
                setSidebarContents([...sidebarContents.slice(0, saqIndex), ...sidebarContents.slice(saqIndex + 1)])
            }
        }
    }, [hasSAQAccess, sidebarContents])

    return (
        <SidebarContainer>
            <ClassSidebar
                    activeClass={activeClass}
                    classes={classes}
                    addClassLabel={"+ Add Class"}
                    onAddClass={() => setCreateClassShown(true)}
                    onClassSelect={(selectedClass) => setActiveClass(selectedClass)}
                    activeLinks={sidebarContents}
                />

            <TeacherAddClass
                activeClass={activeClass}
                show={createClassShown}
                onHide={() => setCreateClassShown(false)}
                classCreated={(newClass) => {
                    setCreateClassShown(false)
                    void refreshClasses()
                        .then((refreshedClasses) => {
                            setActiveClass(findById(newClass.id, refreshedClasses))
                        })
                }}
            />
            
            <SidebarContent>
                <DashboardAuth
                    activeClass={activeClass}
                    loading={loading}
                    redirectUrl="/dashboard/teacher/overview"
                    permission="TEACHER"
                >
                    <Routes>
                        <Route
                            path="/overview"
                            element={
                                <TeacherOverview
                                    activeClass={activeClass}
                                    classes={classes}
                                    onAddClass={() => setCreateClassShown(true)}
                                    onClassSelected={(selectedClass) => {
                                        setActiveClass(findById(selectedClass.id, classes))
                                    }}
                                />
                            }
                        />
                        <Route path="/assignments" element={<TeacherAssignments activeClass={activeClass} refreshClasses={refreshClasses} />} />
                        <Route path="/assignments/search" element={<TeacherAssignmentSearch activeClass={activeClass} />} />
                        <Route path="/assignments/add" element={validateGradingRoute(CreateAssignmentProps, <TeacherCreateAssignment activeClass={activeClass} />)}/>
                        <Route path="/assignments/edit" element={validateGradingRoute(EditAssignmentProps, <TeacherEditAssignment activeClass={activeClass} />)}/>
                        <Route path="/assignments/grading/*" element={<TeacherAssignmentGrading activeClass={activeClass} />} />
                        <Route path="/assignments/:assignmentId/demo-mode" element={<DemoMode activeClass={activeClass} />} />
                        <Route path="/students" element={<TeacherStudents activeClass={activeClass} refreshClasses={refreshClasses} />} />
                        <Route path="/gradebook" element={<GradeBook activeClass={activeClass} refreshClasses={refreshClasses}/>} />
                        <Route path="/analytics" element={<Analytics activeClass={activeClass} />} />
                        <Route path="/lesson-plans" element={<LessonPlans />} />
                        <Route path="/lesson-plans/:planId" element={<LessonPlan activeClass={activeClass} />} />
                        <Route path="/plan-assignment/:assignmentId/preview" element={<PlanAssignmentPreview />} />
                        <Route path="/settings" element={<TeacherClassSettings activeClass={activeClass} refreshClasses={refreshClasses} />} />
                        <Route path="/saq" element={<SAQLandingPage activeClass={activeClass} />} />
                        <Route path="*" element={<Navigate to="/dashboard/teacher/overview" replace={true} />} />
                    </Routes>
                </DashboardAuth>
            </SidebarContent>
        </SidebarContainer>
    )
}
